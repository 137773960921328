<template>
  <div>
    <JPrint ref="doPrint"></JPrint>
    <div class="W-main">
      <div class="W-main-left">
        <div class="sidebar-title">委托单列表</div>
        <div class="sidelist" v-for="(item,key) in Taskauditcomdata" :key="key"
             :class="[active_key === key ? 'active':'']" @click="clickItemJump(item,key)">
          {{ item.task_name }}
        </div>
      </div>
      <div class="W-main-right">
        <a-row>
          <a-col :span="24" style="padding-bottom: 15px">
            <a-form-model layout="inline" :model="form">
              <a-form-model-item label="">
                <a-select style="width: 160px" v-model="form.status" @change="Get_caijilist()">
                  <a-select-option value=''>全部样品</a-select-option>
                  <a-select-option value='1'>采集待处理</a-select-option>
                  <a-select-option value='2'>采集中</a-select-option>
                  <a-select-option value='3'>采集完成</a-select-option>
                  <a-select-option value='4'>接收完成</a-select-option>
                  <a-select-option value='5'>退回</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="">
                <a-input placeholder="请输入样品名称编号进行搜索" style="width: 260px" v-model="form.keywords"
                         @keyup="Get_caijilist()"/>
              </a-form-model-item>
              <a-form-model-item label="">
                <a-button type="danger" @click="Clear()">清空</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="margin-bottom: -10px">
            <a-space style="" class="btner">
              <div class="btnerlist" @click="showDrawerE()">
                <i class="iconfont icon-caiyangyuanshijilu" style=" vertical-align: -1px"></i>编辑样品
              </div>
              <div class="btnerlist" @click="Set_specitem_lingqu()">
                <i class="iconfont icon-weituokehuxinxiguanli" style="font-size: 18px; vertical-align: -1px"></i>领取任务
              </div>
              <div class="btnerlist" @click="showDrawerE()">
                <i class="iconfont icon-xiaoyanzuoye"></i>采集完成
              </div>
              <div class="btnerlist" @click="showDrawerB()">
                <i class="iconfont icon-luruzhong" style="font-size: 15px; vertical-align: -1px"></i>拒收退回记录
              </div>
              <div class="btnerlist" @click="showDrawerT()">
                <i class="iconfont icon-rizhi"></i>样品流转时间轴
              </div>

              <div class="btnerlist">
                <div class="print-tag">
                  <i class="iconfont icon-24px" style="font-size: 18px; vertical-align: -1px"></i>打印样品编号
                  <div class="print-show">
                    <div v-for="(item,index) in templateList" :key="item.id"> {{ item.name }}
                      <img :src="getImg(item)" style="width:150px">
                      <a-button type="primary" @click="PrintTAG(index)">打 印</a-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btnerlist">
                <i class="iconfont icon-weibiaoti545" style="font-size: 18px;"></i>导出筛选数据
              </div>
              <div class="btnerlist">
                <i class="iconfont icon-24px" style="font-size: 18px; vertical-align: -1px"></i>打印采样单
              </div>
              <div v-if="level == 1" class="btnerlist" @click="sendToTaskList()">
                <i class="iconfont icon-24px" style="font-size: 18px; vertical-align: -1px"></i>下发采集任务
              </div>
            </a-space>
          </a-col>
          <a-col :span="24">
            <table class="layui-table" lay-size="sm">
              <thead>
              <tr>
                <th style="width: 3%">
                  <a-checkbox v-model="all_active" @change="allChange"></a-checkbox>
                </th>
                <th style="width: 15%" @click="yinziclickup(1)">
                  <span class="fl">样品名称</span>
                  <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou"
                                            :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou"
                                            :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']"
                                            style=" margin-top: -5px"/>
                                    </span>
                </th>
                <th style="width: 10%" @click="yinziclickup(2)">
                  <span class="fl">样品编号</span>
                  <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou"
                                            :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou"
                                            :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']"
                                            style=" margin-top: -5px"/>
                                    </span>
                </th>
                <th style="width: 10%" @click="yinziclickup(3)">
                  <span class="fl">样品状态</span>
                  <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou"
                                            :class="[ordertype === 3 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou"
                                            :class="[ordertype === 3 && sortrule === 'desc' ?'classname':'']"
                                            style=" margin-top: -5px"/>
                                    </span>
                </th>
                <th>采集人</th>
                <th>采集时间</th>
                <th>采集地点</th>
                <th>采集经纬度</th>
                <th>接收人</th>
                <th>接收时间</th>
                <th>下发状态</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in Get_caijilist_list" :key="key" @click="clickrow(key)">
                <td style="text-align: center">
                  <a-checkbox v-model="item.check"></a-checkbox>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <a-space>
                    {{ item.in_number }}
                  </a-space>
                </td>
                <td>
                  <span v-if="item.status_trun.includes('被退回')" style="color: #ff0000">{{ item.status_trun }}</span>
                  <span v-else>{{ item.status_trun }}</span>
                </td>
                <td>{{ item.caiji_name }}</td>
                <td>{{ item.pick_time }}</td>
                <td>{{ item.pick_space }}</td>
                <td>{{ item.jing_dot }},{{ item.wei_dot }}</td>
                <td>{{ item.jieshou_name }}</td>
                <td>{{ item.get_time }}</td>
                <td>
                  <div v-if="!item.task_list">否</div>
                  <div v-if="item.task_list">
                    <div v-if="Number(item.task_list_status) == 1">待处理</div>
                    <div v-if="Number(item.task_list_status) == 2">已接收</div>
                    <div v-if="Number(item.task_list_status) == 3">已完成</div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-if="info">
      <TimeLine :info="info" :close="onCloseDrawerT" :visible="drawer_visibleT"></TimeLine>
    </div>
    <div v-if="edit_info">
      <EditSample :edit_info="edit_info" :edit_time="edit_time" :close="onCloseDrawerE"
                  :visible="drawer_visibleE"></EditSample>
    </div>
    <div v-if="send_info">
      <SendBack :send_info="send_info" :close="onCloseDrawerB" :visible="drawer_visibleB"></SendBack>
    </div>

    <a-modal v-model:visible="task_list_show" title="下发采集任务" @ok="createTaskList()">
      <div>
        <div>
          <a-space>
            <b>地区：</b>
            <a-select value="2">
              <a-select-option value="2">{{ regname }}</a-select-option>
            </a-select>
          </a-space>
        </div>
        <div>
          <textarea style="height: 400px;border:1px solid #33333340;width: 100%;padding: 10px;margin-top: 10px;" v-model="task_list_content" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import SendBack from "./sendback-list/sendback-list";
import TimeLine from "./time-line/time-line";
import EditSample from "./edit-sample/edit-sample";
import JPrint from "../../../components/print/JPrint";
import Print from "@/utils/print";

export default {
  components: {
    EditSample, TimeLine, SendBack, JPrint,
  },
  data() {
    return {
      level: app_config.level,
      regname:regname,
      Taskauditcomdata: [],
      active_key: -1,
      active_item: false,

      form: {
        status: '',
        keywords: '',
      },
      page_show: false,

      all_active: false,
      page: 1,
      count: '',
      pagesize: '',
      Get_caijilist_list: [],

      edit_time: 0,// 定义控制抽屉显示的变量
      edit_info: false,
      drawer_visibleE: false,
      info: false,
      drawer_visibleT: false,
      send_info: false,
      drawer_visibleB: false,
      templateList: [],//标签模板
      print_value: [], //打印标签
      ordertype: 0,//排序
      sortrule: 'asc',

      task_list_id: 0,
      task_list_show: false,
      task_list_content: '',
    }
  },
  watch: {
    $route: {
      handler() {
        this.active_id = Number(this.$route.query.id ? Number(this.$route.query.id) : 0)
        this.mountedDo()//w获取公告分页列表
      },
      deep: true
    },

  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    createTaskList() {
      // 二级：创建采集任务
      this.$sa0.post({
        url: this.$api('二级：创建采集任务'),
        data: {
          id: this.task_list_id,
          content: this.task_list_content,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_list_show = false
            this.mountedDo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    sendToTaskList() {
      let check_list = [];
      this.Get_caijilist_list.map((item) => {
        if (item.check) {
          check_list.push(item)
        }
      })
      if (check_list.length === 0) {
        return layer.msg('请选择一个采集任务')
      }
      if (check_list.length !== 1) {
        return layer.msg('只能选择一个采集任务')
      }
      if (check_list[0].task_list) {
        return layer.msg('已存在下发采集任务')
      }
      this.task_list_id = check_list[0].id
      console.log(check_list[0])
      this.task_list_content = `样品名称 ${check_list[0].name}
样品编号： ${check_list[0].in_number}
采集地点： ${check_list[0].pick_space}
备注： ${check_list[0].remark ?? ''}`
      this.task_list_show = true
    },
    mountedDo() {
      this.Weituodan_list_() //委托单列表　
      // this.Get_caijilist();
      setTimeout(() => {
        this.page_show = true

      }, 100)
      this.taglist(3)
    },
    PrintTAG(index) {
      let check_list = [];
      this.Get_caijilist_list.map((item) => {
        if (item.check) {
          check_list.push({demo_num: item.in_number, demo_text: item.name})
        }
      })
      if (check_list.length === 0) {
        layer.msg('请选择要打印的样品编号')
      } else {
        //
        let template = this.templateList[index]
        let src_list = check_list.map(item => {
          template.demo_num = item.demo_num
          template.demo_text = item.demo_text
          return Print.getImg(template)
        })
        console.log(check_list)
        this.$refs.doPrint.printIt(src_list);
      }
    },
    taglist(id) {
      this.$sa0.post({
        url: this.$api('Get_tag'),
        data: {
          tag_type: id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.templateList = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    Set_specitem_lingqu() {
      let id = 0
      let count = 0
      // let status_lingqu = ''
      this.Get_caijilist_list.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
          // status_lingqu = item.status_lingqu
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      // if (status_lingqu != 2) return layer.msg('此任务已领取完')

      let data = {
        id: id,
        //status: 2,
      }
      //console.log(data)
      //console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Set_specitem_lingqu'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg('已领取任务')
            this.Get_caijilist();
            //this.close()
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },

    iSet_spec_item(item) {
      let data = {
        id: item.id,
        status: 3,
      }
      //console.log(data)
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Set_spec_item'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg('采集完成')
            this.Get_caijilist();
            //this.close()
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },
    Weituodan_list_(click = 0) {
      this.$sa0.post({
        url: this.$api('Weituodan_list_'),
        data: {
          type: 1
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Taskauditcomdata = response.data.list;
            // 页面加载进来默认选中第一条
            console.log(456987, this.Taskauditcomdata)
            let id = this.$route.query.id || false
            if (id) {
              this.active_id = Number(id)
            }
            if (!this.active_id) {
              if (this.Taskauditcomdata.length) {
                this.clickitem(this.Taskauditcomdata[click], click)
              }
            } else {
              this.Taskauditcomdata.map((item, key) => {
                if (item.task_id === this.active_id) {
                  this.clickitem(this.Taskauditcomdata[key], key)
                }
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    // routerCheck(item, key) {
    //     this.active_item = item;
    //     this.active_key = key;
    //     this.Get_caijilist()
    //     console.log(987456, key)
    // },
    clickItemJump(item, key) {
      this.$router.push({
        name: this.$route.name,
        query: {id: item.task_id}
      })
    },

    clickitem(item, key) {
      this.active_key = key;
      this.active_item = item;
      this.Get_caijilist()
    },

    clickrow(key) {
      this.$set(this.Get_caijilist_list[key], 'check', !this.Get_caijilist_list[key].check)
      this.itemActiveChange()
    },
    itemActiveChange() {
      let do_ = true
      this.Get_caijilist_list.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_active = do_
    },
    allChange(e) {
      this.Get_caijilist_list.map((item, key) => {
        this.$set(this.Get_caijilist_list[key], 'active', this.all_active)
      })
    },
    //清空搜索
    Clear() {
      this.form = {
        status: '',
        keywords: '',
      }
      this.Get_caijilist();
    },
    // 分页
    onChange(page) {
      this.page = page;
      this.Get_caijilist();
    },
    // w采集人列表
    Get_caijilist() {
      this.$sa0.post({
        url: this.$api('Get_caijilist'),
        data: {
          id: this.id,
          status: this.form.status,
          keywords: this.form.keywords,
          page: this.page,
          ordertype: this.ordertype,
          sortrule: this.sortrule,
          task_id: this.active_item.task_id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Get_caijilist_list = response.data.result.list.map((item) => {
              item.check = false
              return item;
            });
            //console.log(this.Get_caijilist_list);
            this.count = Number(response.data.result.count);//分页
            this.pagesize = Number(response.data.result.pagesize);//分页
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    yinziclickup(ordertype) {
      if (this.ordertype !== ordertype) {
        this.ordertype = ordertype
        this.sortrule = 'asc'
      } else {
        if (this.sortrule === 'asc') {
          this.sortrule = 'desc'
        } else {
          this.sortrule = 'asc'
        }
      }
      this.$sa0.post({
        url: this.$api('Get_caijilist'),
        data: {
          id: this.id,
          status: this.form.status,
          keywords: this.form.keywords,
          page: this.page,
          ordertype: this.ordertype,
          sortrule: this.sortrule,
          task_id: this.active_item.task_id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Get_caijilist_list = response.data.result.list.map((item) => {
              item.check = false
              return item;
            });
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    // 定义 打开抽屉时的 函数  时间轴
    showDrawerT() {
      let id = 0
      let count = 0
      let info = []
      this.Get_caijilist_list.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
          info = item
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.edit_info = info
      this.info = info;
      this.drawer_visibleT = true;
    },
    onCloseDrawerT() {
      this.drawer_visibleT = false;
      this.info = false
    },

    // 定义 关闭抽屉时的 函数  编辑样品
    showDrawerE() {
      let id = 0
      let count = 0
      let info = []
      this.Get_caijilist_list.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
          info = item
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.edit_info = info
      //console.log("==============================================")
      //console.log(this.edit_info)
      this.edit_time = new Date() / 1;
      this.drawer_visibleE = true;
    },
    onCloseDrawerE() {
      this.Get_caijilist();
      this.drawer_visibleE = false;
      this.edit_info = false
    },

    // 定义 关闭抽屉时的 函数 拒收退回记录
    showDrawerB() {
      let id = 0
      let count = 0
      let info = []
      this.Get_caijilist_list.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
          info = item
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.send_info = info;
      //console.log(this.send_info);
      this.drawer_visibleB = true;
    },
    onCloseDrawerB() {
      this.drawer_visibleB = false;
      this.send_info = false
    },
    getImg(item) {
      return Print.getImg(item)
    }

  },

}
</script>

<style scoped>
.sidebar-title {
  width: 100%;
  padding: 10px 5px;
  font-weight: bold;
  border-bottom: 1px solid #eeeeee;
  font-size: 14px;
  background: #f9f9f9;
}

.sidelist {
  width: 100%;
  line-height: 30px;
  border-bottom: 1px solid #eeeeee;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidelist:hover {
  background: #eff8ff;
  color: #333333;
}

.active {
  background: #96cbf9;
}

.sidebartit {
  width: 100%;
  background: #f9f9f9;
  height: 60px;
  line-height: 60px;
  padding-left: 10px;
  font-weight: bold
}

.W-main {
  display: flex;
  width: 100%;
}

.W-main-right {
  flex: 1;
  padding-left: 15px
}

.W-main-left {
  width: 250px;
  background: #fdfdfd;
  height: calc(100vh - 150px);
  border: 1px solid #f9f9f9;
}

.treetitle {
  position: absolute;
  /*top: 80px;*/
  /*left: 25px;*/
  z-index: 99999;
  width: 230px;
  height: 40px;
  line-height: 40px;
  background: #f9f9f9;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
  /*padding-left: 10px;*/
  font-weight: bold
}

.treeborder {
  width: 230px;
  border: 1px solid #eeeeee;
  height: calc(100vh - 130px);
  margin-left: 0px;
  border-bottom: none
}

/*******************w按钮区开始w*********************
    ***********************************************/
.btner {
  width: 100%;
  background: #f3f3f3;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #e9e8e8;
  height: 26px;
  line-height: 24px;
  padding: 0 12px
}

.btner:last-child {
  border: none
}

.isthis, .btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
  height: 26px;
  line-height: 24px;
}

.btnerlist i {
  color: #999999;
  font-size: 16px;
  margin-right: 3px;
}

.isthis i, .btnerlist:hover i {
  color: #ffffff !important
}

.isDel, .isDel:hover {
  background: #ff4d4f;
  border-radius: 4px;
  color: #ffffff !important
}

.isDel i, .isDel:hover, .isDel:hover i {
  color: #ffffff !important
}

/*******************w按钮区结束w*********************
***********************************************/

/*******************w打印w*********************
 **************************************************/
.print-tag {
  position: relative;
}

.print-show {
  display: none
}

.print-tag:hover .print-show {
  display: block;
  position: absolute;
  top: 36px;
  left: 0;
  width: 260px;
  height: auto;
  border: solid 1px #efefef;
  background: #fff;
  padding: 15px;
  z-index: 999;
}

/*******************w表格w*********************/
.layui-table th, .layui-table td {
  text-align: left !important;
}

/*******************w排序w*********************/
.ant-table-column-sorter {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  margin-left: 5px;
}

.jiantou {
  color: #999999
}

.classname {
  color: #1890ff;
}

.fl {
  float: left
}
</style>
